const pluralKeys = ['=0', 'one', 'other'] as const;

export function flattenKeysToIcuPlural(obj: Record<string, unknown>, parentKey = ''): Record<string, string> {
  const result: Record<string, string> = {};
  const stack: Array<[Record<string, unknown>, string]> = [[obj, parentKey]];

  for (const [currentObj, currentKey] of stack) {
    for (const key of Object.keys(currentObj)) {
      let value = currentObj[key];
      const nextKey = currentKey ? `${currentKey}.${key}` : key;

      if (typeof value === 'object') {
        stack.push([value as Record<string, unknown>, nextKey]);
      } else {
        if (typeof value === 'string' && value.includes('|')) {
          const pluralsToArray = value.split('|');

          if (pluralsToArray.length > pluralKeys.length) {
            throw new Error(`Plural keys length longer than expected for ${nextKey}`);
          }

          const buildIcuString = (plural: string) => `{count, plural, ${plural}}`;

          let plurals = '';
          pluralsToArray.forEach((plural, index) => {
            let pluralKeyIndex = index;
            if (index === pluralsToArray.length - 1) {
              pluralKeyIndex = 2;
            }
            plurals += `${pluralKeys[pluralKeyIndex]} {${plural.trim()}} `;
          });

          value = buildIcuString(plurals.trim());
        }
        result[nextKey] = value as string;
      }
    }
  }

  return result;
}
