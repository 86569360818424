import cookie, { type CookieSerializeOptions } from 'cookie';

function decodeCookieValue(value: unknown): unknown {
  if (typeof value === 'string') {
    try {
      return JSON.parse(value);
    } catch {
      // fail silently
    }
  }

  return value;
}

export function getCookies() {
  if (typeof document === 'undefined') {
    return {};
  }
  return cookie.parse(document.cookie);
}

export function getCookie(key: string) {
  const cookies = getCookies();
  return decodeCookieValue(cookies[key]);
}

export function setCookie(key: string, value: string, options: CookieSerializeOptions = { path: '/' }) {
  const serializedCookie = cookie.serialize(key, value, options);
  if (typeof document !== 'undefined') {
    document.cookie = serializedCookie;
  }
}
